<template>
  <div>
    <!-- #region Form -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3">{{ $t("articleLinkCreatePage.pageTitle") }}</strong>
          </CCardHeader>
          <CCardBody>
            <span class="caption">
              {{ $t("common.createCaption") }}
            </span>
            <CForm class="submit_form">

              <!-- #region 特集タイトル -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{
                    $t("articleLinkCreatePage.title")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.title" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.title.$dirty && !$v.edit.title.required"
                  >
                    {{ $t("validations.required", { vName: $t("articleLinkCreatePage.title") }) }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.title.$dirty && !$v.edit.title.maxLength
                    "
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("articleLinkCreatePage.title"),
                        vMax: $v.edit.title.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 特集タイトル -->

              <!-- #region 特集URL -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{
                    $t("articleLinkCreatePage.url")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.url" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.url.$dirty && !$v.edit.url.required"
                  >
                    {{ $t("validations.required", { vName: $t("articleLinkCreatePage.url") }) }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.url.$dirty && !$v.edit.url.maxLength"
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("articleLinkCreatePage.url"),
                        vMax: $v.edit.url.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 特集URL -->

              <!-- #region 宿リスト -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{ $t("articleLinkCreatePage.facilityList") }}</strong></CCol>
                <CCol md="3">
                  <CSelect
                    placeholder="未選択"
                    :options="facilityListList"
                    :value.sync="edit.facilityListId"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.facilityListId.$dirty && !$v.edit.facilityListId.required
                    "
                  >
                    {{ $t("validations.required", { vName: $t("articleLinkCreatePage.facilityList") }) }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 宿リスト -->

              <!-- #region 説明 -->
              <CRow>
                <CCol class="label"><strong>{{ $t("common.description") }}</strong></CCol>
                <CCol>
                  <CTextarea
                    class="mb-2"
                    v-model="edit.description"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.description.$dirty &&
                      !$v.edit.description.maxLength
                    "
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("common.description"),
                        vMax: $v.edit.description.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 説明 -->

              <!-- #region 確認ボタン -->
              <CButton
                class="px-5 mt-4"
                color="info"
                @click="onCheckClicked()"
                v-bind:class="activeStatus"
              >
                <i class="cil-task"></i> {{ $t("common.check") }}
              </CButton>
              <!-- #endregion 確認ボタン -->
            </CForm>
          </CCardBody>

          <CCardFooter>
            <CButton
              color="secondary"
              class=""
              @click="onBackClicked()"
            ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Form -->

    <!-- #region Check -->
    <CModal
      color="info"
      size="lg"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="checkModal"
    >
      <CForm class="submit_form">

        <!-- #region 特集タイトル -->
        <CRow>
          <CCol class="label"><strong>{{ $t("articleLinkCreatePage.title") }}</strong></CCol>
          <CCol><span> {{ edit.title }} </span></CCol>
        </CRow>
        <!-- #endregion 特集タイトル -->

        <!-- #region 特集URL -->
        <CRow>
          <CCol class="label"><strong>{{ $t("articleLinkCreatePage.url") }}</strong></CCol>
          <CCol><span> {{ edit.url }} </span></CCol>
        </CRow>
        <!-- #endregion 特集URL -->

        <!-- #region 宿リスト -->
        <CRow>
          <CCol class="label"><strong>{{ $t("articleLinkCreatePage.facilityList") }}</strong></CCol>
          <CCol><span> {{ selectedFacilityListLabel }} </span></CCol>
        </CRow>
        <!-- #endregion 宿リスト -->

        <!-- #region 説明 -->
        <CRow>
          <CCol class="label"><strong>{{ $t("common.description") }}</strong></CCol>
          <CCol><span> {{ edit.description }} </span></CCol>
        </CRow>
        <!-- #endregion 説明 -->

      </CForm>

      <template #header> {{ $t("common.check") }} </template>

      <template #footer>
        <CButton
          @click="checkModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{ $t("common.cancel") }}
        </CButton>

        <CButton
          color="success"
          @click="onRegisterClicked()"
        >
          <i class="icon cil-save mr-1"></i> {{ $t("common.register") }}
        </CButton>
      </template>

    </CModal>
    <!-- #endregion Check -->

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
  </div>
</template>

<script>
// import axios from "axios";

import {
  required,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "articleLinkCreate",

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },

    selectedFacilityListLabel() {
      if (!this.facilityListList || !Array.isArray(this.facilityListList) || !this.facilityListList.length) return "";
      const selected = this.facilityListList.filter(a => a.value === this.edit.facilityListId);
      const label = selected[0] ? selected[0].label : "未選択"; // TODO: ハードコーディング
      return label;
    },

  },

  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },

  data() {
    return {
      //#region Flag
      loading: false,
      checkModal: false,
      successModal: false,
      errorModal: false,
      //#endregion Flag


      //#region Message
      errorMessage: "",
      //#endregion Message


      //#region request
      edit: {},
      //#endregion request


      //#region Options
      /** 宿リスト一覧 */
      facilityListList: [],
      //#endregion Options
    };
  },

  validations() {
    return {
      edit: {
        title: {
          required,
          maxLength: maxLength(255),
        },
        url: {
          required,
          maxLength: maxLength(1000),
        },
        facilityListId: {
          required,
        },
        description: {
          maxLength: maxLength(1000),
        },
      },
    };
  },

  methods: {
    //#region Event
    onCheckClicked() {
      this.$v.edit.$invalid
        ? this.$v.edit.$touch()
        : (this.checkModal = true);
    },

    onRegisterClicked() {
      this.reqPost();
    },

    onBackClicked() {
      this.backPage();
    },
    //#endregion Event


    //#region Request
    /** 宿リスト一覧取得 */
    reqGetFacilityListList() {
      const callback = (a) => {
        this.pourTable(a.lists);
        this.loading = false;
      };

      this.reqGetHotelListAll(callback);
    },

    reqPost() {

      // note: 多言語対応時、editが複数設定される想定
      // const body = [this.edit];
      const body = this.edit;
      
      const callback = () => this.successModal = true;

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPostArticleLink(body, callback, errorCallback);
    },
    //#endregion Request


    //#region Method
    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();

      datas.forEach((data) => {
        if (data.status == 100) {
          this.facilityListList.push({
            value: data.id,
            label: data.name,
          });
        } else {
          return null;
        }
      });
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.facilityListList = [];
    },

    backPage() {
      this.$router.go(-1);
    },
    //#endregion Method
  },

  beforeMount() {
    this.reqGetFacilityListList();
  },
};
</script>